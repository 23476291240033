import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import moment from 'moment';

import ClockIcon from '@icons/clock.svg';

import styles, { StyledArticleTitleNotesDate } from './ArticleTitle.styles';

const {
  StyledArticleTitle,
  StyledPrimaryArticleTitleText,
  StyledSecondaryArticleTitleText,
  StyledArticleTitleLink,
  StyledArticleTitleNotes,
  StyledArticleTitleNotesAuthor,
} = styles;

const ArticleTitle = ({
  title,
  author,
  date,
  isPrimaryHeading = false,
  to = undefined,
}) => {
  const titleText = useMemo(
    () => (to ? (
      <StyledArticleTitleLink to={to}>{title}</StyledArticleTitleLink>
    ) : (
      title
    )),
    [title, to],
  );

  const headingText = useMemo(
    () => (isPrimaryHeading ? (
      <StyledPrimaryArticleTitleText>
        {titleText}
      </StyledPrimaryArticleTitleText>
    ) : (
      <StyledSecondaryArticleTitleText>
        {titleText}
      </StyledSecondaryArticleTitleText>
    )),
    [titleText, isPrimaryHeading],
  );

  const creationDate = moment(date).format('D MMM YYYY');
  return (
    <StyledArticleTitle>
      {!!title && headingText}
      <StyledArticleTitleNotes>
        {!!author && (
          <StyledArticleTitleNotesAuthor>
            By
            {' '}
            {author}
          </StyledArticleTitleNotesAuthor>
        )}
        {!!creationDate && (
          <StyledArticleTitleNotesDate>
            <ClockIcon />
            <span>{creationDate}</span>
          </StyledArticleTitleNotesDate>
        )}
      </StyledArticleTitleNotes>
    </StyledArticleTitle>
  );
};

ArticleTitle.propTypes = {
  author: PropTypes.shape({}).isRequired,
  date: PropTypes.string.isRequired,
  isPrimaryHeading: PropTypes.bool.isRequired,
  title: PropTypes.shape({}).isRequired,
  to: PropTypes.string.isRequired,
};

export default ArticleTitle;
