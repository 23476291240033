import React, { useState } from 'react';
import ArticleTitle from '@components/ArticleTitle';
import Button from '@components/Button';
import ContentfulRichText from '@components/ContentfulRichText';
import { getFirstSection } from '@utils/richText';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import articleContentStyles from '@components/ArticleContent/ArticleContent.styles';
import {
  StyledArticleWrapper,
  StyledButtonWrapper,
} from './ArticleList.styles';

const { StyledArticleImage, StyledArticleContent } = articleContentStyles;

const ArticleList = (props) => {
  const { articles } = props;

  const [articlesToShow, setArticlesToShow] = useState(props.articlesToShow);

  return (
    <StyledArticleWrapper>
      {articles.map(({ node: article }, index) => {
        const firstSectionContent = getFirstSection(article.body);

        return (
          index < articlesToShow && (
            <React.Fragment key={article.slug}>
              <ArticleTitle
                date={article.publishedDate}
                title={article.title}
                author={article.author}
                to={`/company-news/${article.slug}/`}
              />
              <StyledArticleContent>
                <StyledArticleImage>
                  <GatsbyImage image={getImage(article.image)} />
                </StyledArticleImage>
                <ContentfulRichText content={firstSectionContent} />
              </StyledArticleContent>
              <StyledButtonWrapper>
                <Button
                  title="Read more"
                  color="black"
                  to={`/company-news/${article.slug}/`}
                />
              </StyledButtonWrapper>
            </React.Fragment>
          )
        );
      })}

      {articlesToShow < articles.length && (
        <StyledButtonWrapper>
          <Button
            color="white"
            title="Show more"
            onClick={() =>
              setArticlesToShow((articlesToShow) => articlesToShow + 3)
            }
          />
        </StyledButtonWrapper>
      )}
    </StyledArticleWrapper>
  );
};

export default ArticleList;
