import React from 'react';
import { Helmet } from 'react-helmet';
import ArticleList from '@components/ArticleList';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { getFirstParagraphText } from '@utils/richText';
import { sortDescendingPublishedDateNodes } from '../../../utils/sort';

const StyledCompanyNews = styled.div`
  > h1 {
    padding: 48px 32px 0px;

    ${screen.tablet(css`
      font-size: 32px;
    `)}

    ${screen.l(css`
      padding: 46px 16px 16px;
      font-size: 24px;
    `)}
  }
`;

const CompanyAnnouncements = ({ location }) => {
  let articlesToShow = 10;

  if (location.state) {
    articlesToShow = location.state.articlesToShow
      ? location.state.articlesToShow
      : 10;
  }

  const newsArticlesList = useStaticQuery(
    graphql`
      {
        allContentfulArticle(filter: { node_locale: { eq: "en-US" } }) {
          edges {
            node {
              slug
              publishedDate
              image {
                gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
              }
              title
              body {
                raw
              }
            }
          }
        }
      }
    `,
  );
  const descendingDateArticles = newsArticlesList.allContentfulArticle.edges.sort(
    sortDescendingPublishedDateNodes,
  );

  const descriptionText = getFirstParagraphText(descendingDateArticles[0].body);

  return (
    <>
      <Helmet>
        <meta name="description" content={descriptionText} />
        <meta itemProp="description" content={descriptionText} />
        <meta property="og:description" content={descriptionText} />
        <meta property="twitter:description" content={descriptionText} />
      </Helmet>
      <StyledCompanyNews>
        <ArticleList
          articles={descendingDateArticles}
          articlesToShow={articlesToShow}
        />
      </StyledCompanyNews>
    </>
  );
};

export default CompanyAnnouncements;
