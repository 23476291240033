import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import styledArticleContent from '@components/ArticleContent/ArticleContent.styles';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const {
  StyledArticleText,
  StyledArticleQuote,
  StyledArticleContent,
  StyledArticleContentSection,
} = styledArticleContent;

const StyledRichTextArticle = styled(StyledArticleText)`
  p:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const richTextRenderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const isEmptyNode = node.content.every(({ value }) => value === '');
      if (isEmptyNode) {
        return <br />;
      }

      const firstTextOccurence = node.content.find(({ value }) => value !== '');

      const footnoteRegex = /^\[([0-9]*)\]/;
      const match = firstTextOccurence.value?.match(footnoteRegex);

      if (match) {
        return (
          <p>
            <span className="footnote" id={`fn${match[1]}`}>
              {children}
            </span>
          </p>
        );
      }
      return <p>{children}</p>;
    },
    [BLOCKS.QUOTE]: (node, children) => (
      <StyledArticleQuote>{children}</StyledArticleQuote>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { gatsbyImageData, description } = node.data.target;

      return (
        <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />
      );
    },
  },
};

const ContentfulRichText = ({ content }) => (
  <StyledArticleContent>
    <StyledArticleContentSection>
      <StyledRichTextArticle>
        {renderRichText(content, richTextRenderOptions)}
      </StyledRichTextArticle>
    </StyledArticleContentSection>
  </StyledArticleContent>
);

export default ContentfulRichText;
