import styled, { css } from 'styled-components';
import { screen } from '@styles/mixins/breakpoints';
import { colors, fonts, getTypographyStyles } from '@styles/variables';
import { buttonCssRules } from '@components/Button/Button';

const StyledArticleContent = styled.div``;

const StyledArticleContentSection = styled.div`
  &:not(:last-child) {
    margin-bottom: 36px;

    ${screen.l(css`
      margin-bottom: 24px;
    `)}
  }
`;

const StyledArticleHeading = styled.h3`
  ${getTypographyStyles.heading3()}

  ${({ cssRules }) => cssRules}
`;

const StyledArticleText = styled.div`
  letter-spacing: 0.12px;
  ${getTypographyStyles.body()}

  h1 {
    ${getTypographyStyles.heading1()}
  }

  h2 {
    ${getTypographyStyles.heading2()}
  }

  h3 {
    ${getTypographyStyles.heading3()}
  }

  h4 {
    ${getTypographyStyles.heading4()}
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
  b {
    font-weight: 900;
  }
  i {
    font-style: italic;
  }
  a {
    color: ${colors.colorCeruleanBlue};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      color: ${colors.colorBlack};
      text-decoration: underline;
    }
  }
  .footnote {
    font-size: 10px;
  }

  ol,
  ul {
    list-style: revert;
    margin-left: 42px;
    li {
      margin-bottom: 8px;
    }
  }

  ${({ cssRules }) => cssRules}
`;

const StyledArticleQuote = styled.div`
  padding-left: 33px;
  border-left: 8px solid #666666;
  color: #666666;
  ${getTypographyStyles.heading3()}
  letter-spacing: 0.12px;

  &:not(:last-child) {
    margin-bottom: 16px;

    ${screen.l(css`
      margin-bottom: 16px;
    `)}
  }

  a {
    color: ${colors.colorBlack};
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
`;

const StyledArticleImage = styled.div`
  padding-top: 16px;
  &:not(:last-child) {
    margin-bottom: 32px;

    ${screen.l(css`
      margin-bottom: 24px;
    `)}
  }

  img {
    display: block;
    width: 100%;
    max-width: 100%;
  }
`;

const StyledArticleButton = styled.div`
  display: flex;
  justify-content: center;
  &:not(:last-child) {
    margin-top: 32px;
    margin-bottom: 32px;

    ${screen.l(css`
      margin-bottom: 24px;
    `)}
  }
`;

const StyledLinkToDownload = styled.a`
  ${buttonCssRules}
`;

export default {
  StyledArticleHeading,
  StyledArticleText,
  StyledArticleQuote,
  StyledArticleImage,
  StyledArticleButton,
  StyledArticleContentSection,
  StyledArticleContent,
  StyledLinkToDownload,
};
