module.exports.getFirstSection = (document) => {
  const raw = JSON.parse(document.raw);

  return {
    raw: JSON.stringify({
      nodeType: 'document',
      data: {},
      content: [{ ...raw.content[0] }],
    }),
  };
};

module.exports.getFirstParagraphText = (richTextQuery) => {
  const raw = JSON.parse(richTextQuery?.raw || '{}');

  const firstParagraph = raw?.content?.find?.(
    ({ nodeType }) => nodeType === 'paragraph',
  );

  return firstParagraph
    ? firstParagraph?.content?.find(({ nodeType }) => nodeType === 'text')
      ?.value
    : '';
};
