import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { screen } from '@styles/mixins/breakpoints';
import { fonts, getTypographyStyles } from '@styles/variables';

const StyledArticleTitle = styled.div`
  border-bottom: 1px solid #bababa;
  margin-bottom: 16px;
  letter-spacing: 0.12px;
  padding-top: 28px;
  
  ${screen.l(css`
    padding: 20px 0;
    margin-bottom: 15px;
  `)}
`;

const articleTitleTextCssRules = css`
  ${getTypographyStyles.heading1()};

  ${screen.l(css`
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 32px;
  `)}
`;

const StyledPrimaryArticleTitleText = styled.h1`
  ${articleTitleTextCssRules}
`;

const StyledSecondaryArticleTitleText = styled.h3`
  ${articleTitleTextCssRules}
`;

const StyledArticleTitleLink = styled(Link)`
  text-decoration: none;
  color: black;
  ${articleTitleTextCssRules}

  &:active {
    color: -webkit-activelink;
  }
`;

const StyledArticleTitleNotes = styled.div`
  display: flex;
  align-items: center;
  ${getTypographyStyles.body()}

  ${screen.l(css`
    flex-wrap: wrap;
    font-size: 12px;
  `)}
`;

const StyledArticleTitleNotesAuthor = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  ${getTypographyStyles.body(true)}

  &::after {
    display: inline-block;
    width: 6px;
    padding-top: 3px;
    border-bottom: 1px solid $color-black;
    margin-left: 6px;
    content: '';
  }
`;

export const StyledArticleTitleNotesDate = styled.div`
  display: flex;
  align-items: center;
  ${getTypographyStyles.body(true)}
  ${({ cssRules }) => cssRules}

  svg {
    margin-right: 8px;
  }
`;

const StyledArticleTitleNotesRead = styled.div`
  display: flex;
  align-items: center;
`;

export default {
  StyledArticleTitle,
  StyledPrimaryArticleTitleText,
  StyledSecondaryArticleTitleText,
  StyledArticleTitleLink,
  StyledArticleTitleNotes,
  StyledArticleTitleNotesAuthor,
  StyledArticleTitleNotesDate,
  StyledArticleTitleNotesRead,
};
