import styled, { css } from 'styled-components';
import { widths, indents, colors } from '@styles/variables';
import { screen } from '@styles/mixins/breakpoints';

export const StyledArticleWrapper = styled.div`
  max-width: ${widths.l};
  padding: 0 ${indents.l};
  margin: 0 auto;

  ${screen.l(css`
    padding: 0 calc(${indents.sm} / 2);
  `)}
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 0 60px;

  .bordered {
    border: solid 1px ${colors.colorBlack};
  }
`;
